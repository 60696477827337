<template>
  <UApp>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <Modal ref="modal"></Modal>
  </UApp>
</template>

<script lang="ts" setup>
import chalk from "chalk";

const config = useRuntimeConfig();
const nuxtApp = useNuxtApp();

const modal = ref(null);

useHead({
  script: [{
    src: "/libraries/webfont.js"
  }],
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${ titleChunk } | ${ config.public.appName }` : "Printags";
  }
});

// @ts-ignore
onMounted(() => {
  (function(d,t) {
    const BASE_URL="https://chatwoot.printags.com";
    const g=d.createElement(t),s=d.getElementsByTagName(t)[0];
    g.src=BASE_URL+"/packs/js/sdk.js";
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g,s);
    g.onload=function(){
      window.addEventListener("chatwoot:ready", function () {
        console.log(chalk.green("Chatwoot is ready"));
        const { $user, $account } = useNuxtApp();
        setTimeout(() => {
          if ($user) {
            const identifier = $user.id
            window.$chatwoot.setUser(identifier, {
              email: $user.email,
              name: $user.firstname + " " + $user.lastname,
              phone_number: String($user.phone),
              identifier_hash: $user.chatwootHash,
              company_name: $account.displayname ? $account.displayname : undefined,
            });
          }
        }, 300)
      });
      window.addEventListener("chatwoot:error", function (err) {
        console.log(chalk.red("Chatwoot error"), err);
      })

      window.chatwootSDK.run({
        locale: 'fr_fr',
        websiteToken: 'kYGU4dB1tHob2fWEZwUt6qNd',
        baseUrl: BASE_URL
      });
    }
  })(document,"script");
});

onMounted(() => {
  if (!nuxtApp.$swal) nuxtApp.provide("swal", modal.value);
});
const env = config.public.nodeEnv;
if (env === "production") {
  console.log(chalk.green("HubSpot tracking is enabled"));
  useHead({
    script: [{
      src: "//js-eu1.hs-scripts.com/145177704.js",
      id: "hs-script-loader",
      async: true
    }]
  });
} else {
  console.log(chalk.yellow("HubSpot tracking is disabled in development mode"), env);
}
window.hsConversationsSettings = {
  loadImmediately: false
};
</script>

<script lang="ts">

export default {
  name: "Index"
  // FIXME: Need a Hubspot license to use this feature
  /*mounted() {
    const config = useRuntimeConfig();
    const env = config.public.env;
    if (env === "prod" && nuxtApp.$user) {
      fetch("/trakingToken").then((res) => {
        const { email, token } = res;
        window.hsConversationsSettings = {
          identificationEmail: email,
          identificationToken: token
        };
        window.HubSpotConversations.widget.load();
        console.log(chalk.green("HubSpot widget is enabled"));
      });
    }
  }*/
};

</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
