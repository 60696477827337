
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexQwDjgSDxHDI0TxKEAOFFXrW5MT6K_45fV_9kXC5ptwtq0Meta } from "/app/pages/customers/index.vue?macro=true";
import { default as index_45F_uAQC8N4LTDgd1cq42C2ftJy11F_99xjd_45gOWaGcoMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as socketzLIW7LhZMcIybKTfABiFdOp14W3DqFO7z28SvPwTD_45gMeta } from "/app/pages/dev/socket.vue?macro=true";
import { default as indexb2obLVbnagwIUZRC5bE90RqSK0lmkU1ppAWfI5qw6Y4Meta } from "/app/pages/developers/index.vue?macro=true";
import { default as apizI_45RuAh4WyeLAafdGlYthReY6DXRC9DPnvhOSQbjzBkMeta } from "/app/pages/docs/api.vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93lDIeSz2wOF34RzwIu_45BAX4BgVwsMW2q9J7W_45FtVejv8Meta } from "/app/pages/invite/[id].vue?macro=true";
import { default as index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta } from "/app/pages/login/index.vue?macro=true";
import { default as logoutUcooZuBFq6Y_45qkSHe3Slgw7Ozl4rsMv5kY96MDZ8icoMeta } from "/app/pages/logout.vue?macro=true";
import { default as accountsZUC8GZDcT3pGmiGHmlSZzLtcF_45z6amniaz5L9jDKuzAMeta } from "/app/pages/management/accounts.vue?macro=true";
import { default as amazonymfW4G3FeCRDgzm_45ktsFx0OU9oVEjk6mKsMYv3urPVAMeta } from "/app/pages/management/amazon.vue?macro=true";
import { default as configWcULm6yAmk4o_47gcfgQNYeydJ0Ix97VdBIui4u3YaMMeta } from "/app/pages/management/config.vue?macro=true";
import { default as indexcSE4CkXdMMAYDEjx3ppz8s9n3joRFHI0fpQOqfc68QAMeta } from "/app/pages/management/index.vue?macro=true";
import { default as inspectorYmbH0w_SOzv_45tmdJgAKISeQ4hf3k_m8uwC9AlEPDxj0Meta } from "/app/pages/management/inspector.vue?macro=true";
import { default as orderszCnegv5QsjQNmr208NSg069JeUYyQX_QIJYh9p_abYgMeta } from "/app/pages/management/orders.vue?macro=true";
import { default as statusvO48sOxpzzwL1FYClyE8PPm3cm25uL7I8CDAk82jF1EMeta } from "/app/pages/management/status.vue?macro=true";
import { default as create_46oldEOmwJMh4akdSK9JaTYdKWTE_EwaHkfjgPfUckwOISQkMeta } from "/app/pages/orders/create.old.vue?macro=true";
import { default as create_w24JVjVPS7FAEMSpkfAteA9DqN6lyiZYCKqNBR8pNQMeta } from "/app/pages/orders/create.vue?macro=true";
import { default as indexDCqhPzutOZTeEBErhbpXyxz9LSZCrOqFoLSpaIOOmIIMeta } from "/app/pages/orders/index.vue?macro=true";
import { default as _91id_93XFqEqZb6Xt4Kvs7NEURLJR2ZSkBPbHMrzn4tNowyoAAMeta } from "/app/pages/password-reset/[id].vue?macro=true";
import { default as indexa7JIWW5Wwy_45k1sMLugZenV3f1e0K_45hdjqoGW23izk88Meta } from "/app/pages/password-reset/index.vue?macro=true";
import { default as indexxAc4Y53avL8uOViqBui4IoShKJWHAzwvvMoBQbO0M_45oMeta } from "/app/pages/payments/index.vue?macro=true";
import { default as indexEP180Yte9j5FYHqKOKcwLWqDK4mSPrhNYq9YGvhURlIMeta } from "/app/pages/printers/index.vue?macro=true";
import { default as productions1kuMofgO5rNS5iQDOj3Rbr0q8UNGrZmNTSQsuliwTp8Meta } from "/app/pages/printers/productions.vue?macro=true";
import { default as stocksSpa5tTGyupaD6qbHDX4wtBsUFgPHNFyr4g_45eOuruLs0Meta } from "/app/pages/printers/stocks.vue?macro=true";
import { default as transactions0gAW2BBULiyimWdwZTcsGIeO2J8Mgwlp687_45FkhPhmkMeta } from "/app/pages/printers/transactions.vue?macro=true";
import { default as _91id_93wwX_FREXDwBWoYAL84CknW5yzeJhIbSSR5bA3y3A2YMMeta } from "/app/pages/register/[id].vue?macro=true";
import { default as indexyamhl0wGj7h0OUvZf48XLG1L3YBKP6iUdj9ycXy74rUMeta } from "/app/pages/register/index.vue?macro=true";
import { default as accountYaW2Hju_BYde55CgkuaY5YUTw34fttXrs6fR3vDa4fsMeta } from "/app/pages/settings/account.vue?macro=true";
import { default as billingxLC_slppFM8RXhaAChpDrb5AmAWYUxU5hgjtO41Mrx8Meta } from "/app/pages/settings/billing.vue?macro=true";
import { default as userCsQ3oKZxGbvqInuO4rajkdYazHVDgW6pmcDowf8zdzUMeta } from "/app/pages/settings/user.vue?macro=true";
import { default as indexv46crOPEOD1SXWkWSW_45c6zJmxY7RF9_45TCFlqQM21EmIMeta } from "/app/pages/stocks/index.vue?macro=true";
import { default as indexsWe5rLV7JSWva21uKolu1bwI7OB2OqsLbcx3KD9fmNMMeta } from "/app/pages/subscriptions/index.vue?macro=true";
import { default as indexZdOI5z1Cd4ocPHGvnaBe78eX64Aze6fApj9uUNKgpN0Meta } from "/app/pages/team/index.vue?macro=true";
export default [
  {
    name: "customers___en",
    path: "/en/customers",
    component: () => import("/app/pages/customers/index.vue")
  },
  {
    name: "customers___fr",
    path: "/customers",
    component: () => import("/app/pages/customers/index.vue")
  },
  {
    name: "dashboard___en",
    path: "/en/dashboard",
    component: () => import("/app/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___fr",
    path: "/dashboard",
    component: () => import("/app/pages/dashboard/index.vue")
  },
  {
    name: "dev-socket___en",
    path: "/en/dev/socket",
    component: () => import("/app/pages/dev/socket.vue")
  },
  {
    name: "dev-socket___fr",
    path: "/dev/socket",
    component: () => import("/app/pages/dev/socket.vue")
  },
  {
    name: "developers___en",
    path: "/en/developers",
    component: () => import("/app/pages/developers/index.vue")
  },
  {
    name: "developers___fr",
    path: "/developers",
    component: () => import("/app/pages/developers/index.vue")
  },
  {
    name: "docs-api___en",
    path: "/en/docs/api",
    component: () => import("/app/pages/docs/api.vue")
  },
  {
    name: "docs-api___fr",
    path: "/docs/api",
    component: () => import("/app/pages/docs/api.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "invite-id___en",
    path: "/en/invite/:id()",
    meta: _91id_93lDIeSz2wOF34RzwIu_45BAX4BgVwsMW2q9J7W_45FtVejv8Meta || {},
    component: () => import("/app/pages/invite/[id].vue")
  },
  {
    name: "invite-id___fr",
    path: "/invite/:id()",
    meta: _91id_93lDIeSz2wOF34RzwIu_45BAX4BgVwsMW2q9J7W_45FtVejv8Meta || {},
    component: () => import("/app/pages/invite/[id].vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta || {},
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: "login___fr",
    path: "/login",
    meta: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta || {},
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: "logout___en",
    path: "/en/logout",
    meta: logoutUcooZuBFq6Y_45qkSHe3Slgw7Ozl4rsMv5kY96MDZ8icoMeta || {},
    component: () => import("/app/pages/logout.vue")
  },
  {
    name: "logout___fr",
    path: "/logout",
    meta: logoutUcooZuBFq6Y_45qkSHe3Slgw7Ozl4rsMv5kY96MDZ8icoMeta || {},
    component: () => import("/app/pages/logout.vue")
  },
  {
    name: "management-accounts___en",
    path: "/en/management/accounts",
    component: () => import("/app/pages/management/accounts.vue")
  },
  {
    name: "management-accounts___fr",
    path: "/management/accounts",
    component: () => import("/app/pages/management/accounts.vue")
  },
  {
    name: "management-amazon___en",
    path: "/en/management/amazon",
    component: () => import("/app/pages/management/amazon.vue")
  },
  {
    name: "management-amazon___fr",
    path: "/management/amazon",
    component: () => import("/app/pages/management/amazon.vue")
  },
  {
    name: "management-config___en",
    path: "/en/management/config",
    component: () => import("/app/pages/management/config.vue")
  },
  {
    name: "management-config___fr",
    path: "/management/config",
    component: () => import("/app/pages/management/config.vue")
  },
  {
    name: "management___en",
    path: "/en/management",
    component: () => import("/app/pages/management/index.vue")
  },
  {
    name: "management___fr",
    path: "/management",
    component: () => import("/app/pages/management/index.vue")
  },
  {
    name: "management-inspector___en",
    path: "/en/management/inspector",
    component: () => import("/app/pages/management/inspector.vue")
  },
  {
    name: "management-inspector___fr",
    path: "/management/inspector",
    component: () => import("/app/pages/management/inspector.vue")
  },
  {
    name: "management-orders___en",
    path: "/en/management/orders",
    component: () => import("/app/pages/management/orders.vue")
  },
  {
    name: "management-orders___fr",
    path: "/management/orders",
    component: () => import("/app/pages/management/orders.vue")
  },
  {
    name: "management-status___en",
    path: "/en/management/status",
    component: () => import("/app/pages/management/status.vue")
  },
  {
    name: "management-status___fr",
    path: "/management/status",
    component: () => import("/app/pages/management/status.vue")
  },
  {
    name: "orders-create.old___en",
    path: "/en/orders/create.old",
    component: () => import("/app/pages/orders/create.old.vue")
  },
  {
    name: "orders-create.old___fr",
    path: "/orders/create.old",
    component: () => import("/app/pages/orders/create.old.vue")
  },
  {
    name: "orders-create___en",
    path: "/en/orders/create",
    component: () => import("/app/pages/orders/create.vue")
  },
  {
    name: "orders-create___fr",
    path: "/orders/create",
    component: () => import("/app/pages/orders/create.vue")
  },
  {
    name: "orders___en",
    path: "/en/orders",
    component: () => import("/app/pages/orders/index.vue")
  },
  {
    name: "orders___fr",
    path: "/orders",
    component: () => import("/app/pages/orders/index.vue")
  },
  {
    name: "password-reset-id___en",
    path: "/en/password-reset/:id()",
    meta: _91id_93XFqEqZb6Xt4Kvs7NEURLJR2ZSkBPbHMrzn4tNowyoAAMeta || {},
    component: () => import("/app/pages/password-reset/[id].vue")
  },
  {
    name: "password-reset-id___fr",
    path: "/password-reset/:id()",
    meta: _91id_93XFqEqZb6Xt4Kvs7NEURLJR2ZSkBPbHMrzn4tNowyoAAMeta || {},
    component: () => import("/app/pages/password-reset/[id].vue")
  },
  {
    name: "password-reset___en",
    path: "/en/password-reset",
    meta: indexa7JIWW5Wwy_45k1sMLugZenV3f1e0K_45hdjqoGW23izk88Meta || {},
    component: () => import("/app/pages/password-reset/index.vue")
  },
  {
    name: "password-reset___fr",
    path: "/password-reset",
    meta: indexa7JIWW5Wwy_45k1sMLugZenV3f1e0K_45hdjqoGW23izk88Meta || {},
    component: () => import("/app/pages/password-reset/index.vue")
  },
  {
    name: "payments___en",
    path: "/en/payments",
    component: () => import("/app/pages/payments/index.vue")
  },
  {
    name: "payments___fr",
    path: "/payments",
    component: () => import("/app/pages/payments/index.vue")
  },
  {
    name: "printers___en",
    path: "/en/printers",
    component: () => import("/app/pages/printers/index.vue")
  },
  {
    name: "printers___fr",
    path: "/printers",
    component: () => import("/app/pages/printers/index.vue")
  },
  {
    name: "printers-productions___en",
    path: "/en/printers/productions",
    meta: productions1kuMofgO5rNS5iQDOj3Rbr0q8UNGrZmNTSQsuliwTp8Meta || {},
    component: () => import("/app/pages/printers/productions.vue")
  },
  {
    name: "printers-productions___fr",
    path: "/printers/productions",
    meta: productions1kuMofgO5rNS5iQDOj3Rbr0q8UNGrZmNTSQsuliwTp8Meta || {},
    component: () => import("/app/pages/printers/productions.vue")
  },
  {
    name: "printers-stocks___en",
    path: "/en/printers/stocks",
    component: () => import("/app/pages/printers/stocks.vue")
  },
  {
    name: "printers-stocks___fr",
    path: "/printers/stocks",
    component: () => import("/app/pages/printers/stocks.vue")
  },
  {
    name: "printers-transactions___en",
    path: "/en/printers/transactions",
    component: () => import("/app/pages/printers/transactions.vue")
  },
  {
    name: "printers-transactions___fr",
    path: "/printers/transactions",
    component: () => import("/app/pages/printers/transactions.vue")
  },
  {
    name: "register-id___en",
    path: "/en/register/:id()",
    meta: _91id_93wwX_FREXDwBWoYAL84CknW5yzeJhIbSSR5bA3y3A2YMMeta || {},
    component: () => import("/app/pages/register/[id].vue")
  },
  {
    name: "register-id___fr",
    path: "/register/:id()",
    meta: _91id_93wwX_FREXDwBWoYAL84CknW5yzeJhIbSSR5bA3y3A2YMMeta || {},
    component: () => import("/app/pages/register/[id].vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: indexyamhl0wGj7h0OUvZf48XLG1L3YBKP6iUdj9ycXy74rUMeta || {},
    component: () => import("/app/pages/register/index.vue")
  },
  {
    name: "register___fr",
    path: "/register",
    meta: indexyamhl0wGj7h0OUvZf48XLG1L3YBKP6iUdj9ycXy74rUMeta || {},
    component: () => import("/app/pages/register/index.vue")
  },
  {
    name: "settings-account___en",
    path: "/en/settings/account",
    component: () => import("/app/pages/settings/account.vue")
  },
  {
    name: "settings-account___fr",
    path: "/settings/account",
    component: () => import("/app/pages/settings/account.vue")
  },
  {
    name: "settings-billing___en",
    path: "/en/settings/billing",
    component: () => import("/app/pages/settings/billing.vue")
  },
  {
    name: "settings-billing___fr",
    path: "/settings/billing",
    component: () => import("/app/pages/settings/billing.vue")
  },
  {
    name: "settings-user___en",
    path: "/en/settings/user",
    component: () => import("/app/pages/settings/user.vue")
  },
  {
    name: "settings-user___fr",
    path: "/settings/user",
    component: () => import("/app/pages/settings/user.vue")
  },
  {
    name: "stocks___en",
    path: "/en/stocks",
    component: () => import("/app/pages/stocks/index.vue")
  },
  {
    name: "stocks___fr",
    path: "/stocks",
    component: () => import("/app/pages/stocks/index.vue")
  },
  {
    name: "subscriptions___en",
    path: "/en/subscriptions",
    component: () => import("/app/pages/subscriptions/index.vue")
  },
  {
    name: "subscriptions___fr",
    path: "/subscriptions",
    component: () => import("/app/pages/subscriptions/index.vue")
  },
  {
    name: "team___en",
    path: "/en/team",
    component: () => import("/app/pages/team/index.vue")
  },
  {
    name: "team___fr",
    path: "/team",
    component: () => import("/app/pages/team/index.vue")
  }
]