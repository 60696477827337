import revive_payload_client_Ksiu3Mf3eBpXpvrhl12PLbXm4ErYTUfrckai79Z_lVM from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.3.1_eslint@9.19.0_jiti@2.4.2__iore_cni4yn4ubjstrahtuw4uw3ow3q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_OvlYxvFfUs_5ZPpRDWZgHzGL3j89wmMgq9C2QMHLrHc from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.3.1_eslint@9.19.0_jiti@2.4.2__iore_cni4yn4ubjstrahtuw4uw3ow3q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Lr5xS83nPSm86GgBxkTOA70ccruGgSS5wpTLsIJZbho from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.3.1_eslint@9.19.0_jiti@2.4.2__iore_cni4yn4ubjstrahtuw4uw3ow3q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_MjvU0lKI2lkEOpnmpw2msrMynBsiMNayXiPGrZOhVF8 from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.3.1_eslint@9.19.0_jiti@2.4.2__iore_cni4yn4ubjstrahtuw4uw3ow3q/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_9X_IOzOuDG0TL2aLnYDyz_Fd4dA8XgoYWCPggenUucc from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.3.1_eslint@9.19.0_jiti@2.4.2__iore_cni4yn4ubjstrahtuw4uw3ow3q/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_tNsZ2a0xCLm3sm4u58T9hIPpxnukIUxlchb9hyIB1Yg from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.3.1_eslint@9.19.0_jiti@2.4.2__iore_cni4yn4ubjstrahtuw4uw3ow3q/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_SoYGxePboQ7btoMPSADAkRhRLpHg1CFGgpeKD_nlPPg from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.3.1_eslint@9.19.0_jiti@2.4.2__iore_cni4yn4ubjstrahtuw4uw3ow3q/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_lhWkRTv0W6sNfWyL8HB3qejT_vPE8Q1QfkWsvwI4_bg from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.3.1_eslint@9.19.0_jiti@2.4.2__iore_cni4yn4ubjstrahtuw4uw3ow3q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_d9T8RITLxhNtYyZsmg2lITEg4mvF7lI1GA0e_svGh_w from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.3.1_eslint@9.19.0_jiti@2.4.2__iore_cni4yn4ubjstrahtuw4uw3ow3q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_wN_QzOt6BFIqx7uoQ1RukBiQZ_bSyW8mh9_J3dsPjgk from "/app/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5_rollup@4.36.0_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_UmFYf_pm0Pxj2rh6k8uBsuGt2L1sXe48SPKdQuoioig from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.36.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_yQ6bjtulJQTkva7G1lSAUIC8MGT2NfqI7qllkXJuLsI from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.36.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import colors_cHbP9QJue2mBy9mL0WsykqKqmnaVNgZ_3gCf6_ozQ3w from "/app/node_modules/.pnpm/@nuxt+ui@3.0.0_@babel+parser@7.26.7_change-case@5.4.4_db0@0.3.1_embla-carousel@8.5.2_focus-tr_5dgfhrnnmxhhi22bk44psk33qe/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_BB4Un3DhuFEPnZhESyKTHEyuJjBFKUgYxaGeon_S_pw from "/app/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.2_@types+node@22.9.0_jiti@2.4.2_lightningcss@1.29.2_7y4vulcaf4rlv2b67phh36ysga/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import autoAnimate_QiO2V8Zvh6DdU1mqQDzlwGnU_GEbw6h_auT8Rey0eWc from "/app/plugins/autoAnimate.js";
import google_recaptcha_3eLQazCSqDGZ_eSLnukyetOWDUMTzb3l_lthO4JzEMs from "/app/plugins/google-recaptcha.ts";
import konva_3yi3aYrlRnIBGGwwD9fwcxLcUlwc0kNjYuHXPmNYMJ0 from "/app/plugins/konva.js";
import mitt_ObbdjDjG_d2Fc_dfzWSItLuGPCkUOSSo8dQ97jBUADc from "/app/plugins/mitt.js";
import sentry_mW3XGrtPCuZcBmJuVnT9v_bqG2TVSyf5dPs2Yj6o6FM from "/app/plugins/sentry.ts";
import socket_client_fywh7ji_97mC6qf2KELUez9W3z_aFBUPhqdVAjFjDuI from "/app/plugins/socket.client.js";
import vuesax_cNkC5RPVbk4Gd7YY2SWAg9AvumXzUvAy2K4asqh9S7w from "/app/plugins/vuesax.js";
import plugin_auto_pageviews_client_W56_Mesnn4Rmc9dqrLGWeV_DYeq8nlDKiaPsvq4KrE4 from "/app/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5_rollup@4.36.0_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_Ksiu3Mf3eBpXpvrhl12PLbXm4ErYTUfrckai79Z_lVM,
  unhead_OvlYxvFfUs_5ZPpRDWZgHzGL3j89wmMgq9C2QMHLrHc,
  router_Lr5xS83nPSm86GgBxkTOA70ccruGgSS5wpTLsIJZbho,
  payload_client_MjvU0lKI2lkEOpnmpw2msrMynBsiMNayXiPGrZOhVF8,
  navigation_repaint_client_9X_IOzOuDG0TL2aLnYDyz_Fd4dA8XgoYWCPggenUucc,
  check_outdated_build_client_tNsZ2a0xCLm3sm4u58T9hIPpxnukIUxlchb9hyIB1Yg,
  view_transitions_client_SoYGxePboQ7btoMPSADAkRhRLpHg1CFGgpeKD_nlPPg,
  chunk_reload_client_lhWkRTv0W6sNfWyL8HB3qejT_vPE8Q1QfkWsvwI4_bg,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_d9T8RITLxhNtYyZsmg2lITEg4mvF7lI1GA0e_svGh_w,
  plugin_client_wN_QzOt6BFIqx7uoQ1RukBiQZ_bSyW8mh9_J3dsPjgk,
  switch_locale_path_ssr_UmFYf_pm0Pxj2rh6k8uBsuGt2L1sXe48SPKdQuoioig,
  i18n_yQ6bjtulJQTkva7G1lSAUIC8MGT2NfqI7qllkXJuLsI,
  colors_cHbP9QJue2mBy9mL0WsykqKqmnaVNgZ_3gCf6_ozQ3w,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  plugin_BB4Un3DhuFEPnZhESyKTHEyuJjBFKUgYxaGeon_S_pw,
  autoAnimate_QiO2V8Zvh6DdU1mqQDzlwGnU_GEbw6h_auT8Rey0eWc,
  google_recaptcha_3eLQazCSqDGZ_eSLnukyetOWDUMTzb3l_lthO4JzEMs,
  konva_3yi3aYrlRnIBGGwwD9fwcxLcUlwc0kNjYuHXPmNYMJ0,
  mitt_ObbdjDjG_d2Fc_dfzWSItLuGPCkUOSSo8dQ97jBUADc,
  sentry_mW3XGrtPCuZcBmJuVnT9v_bqG2TVSyf5dPs2Yj6o6FM,
  socket_client_fywh7ji_97mC6qf2KELUez9W3z_aFBUPhqdVAjFjDuI,
  vuesax_cNkC5RPVbk4Gd7YY2SWAg9AvumXzUvAy2K4asqh9S7w,
  plugin_auto_pageviews_client_W56_Mesnn4Rmc9dqrLGWeV_DYeq8nlDKiaPsvq4KrE4
]